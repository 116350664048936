import React, { FC } from 'react';
import { graphql } from 'gatsby';

import SubMenu from 'components/SubMenu';

import { IPropsPageSubMenu } from './model';

const PageSubMenu: FC<IPropsPageSubMenu> = ({ ariaLabel, links, minItemsNumber = 1 }) =>
  links?.length >= minItemsNumber ? (
    <SubMenu ariaLabel={ariaLabel} links={links} topOffset={160} />
  ) : null;

export const query = graphql`
  fragment FragmentPageSubMenu on IPageSubMenu {
    pageSubMenuAriaLabel
  }
`;

export default PageSubMenu;
